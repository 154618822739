import React, {Fragment} from 'react';

/**
 * Contact Widget
 * @returns {*}
 * @constructor
 */
function ContactWidget() {

    return (
        <Fragment>
            <div className="widget contact-widget">
                <h3>Contact info</h3>
                <ul>
                    <li>Phone: +90 539 564 77 57</li>
                    <li>Email: support@anatoliantex.com</li>
                    <li>Address: Halkapınar, Mürselpaşa Blv. Yanyolu No:166, 35170 Konak/İzmir</li>
                </ul>
            </div>
        </Fragment>
    );
}

export default ContactWidget;
