 import React, {Fragment, useEffect, useState} from 'react';

import Footer from '../../components/global/Footer';
import Instagram from '../../components/global/Instagram';
import PageTitle from '../../components/global/PageTitle';
import Header from '../../components/header/Header';
import CartItem from "../../components/cart/CartItem";
import Coupon from "../../components/cart/Coupon";
import CalculatedShipping from "../../components/cart/CalculatedShipping";

import './cart.css';
 import {useDispatch, useSelector} from "react-redux";
 import {getAllProducts, submitQuotationForm} from "../../redux/services";
 import {handleInputChange} from "../../utils";
 import { useAlert } from 'react-alert'

/**
 * Cart page
 * @param options
 * @returns {*}
 * @constructor
 */
function Cart({ options }) {

    const alert = useAlert()
    const dispatch = useDispatch();
    const {
        productState,
        cartState,
        quotationFormState
    } = useSelector((state) => ({
        productState: state.products.products,
        cartState: state.cart.products,
        quotationFormState: state.forms.quotationForm
    }))

    const [cartProducts, setCartProducts] = useState();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    })




    useEffect(() => {
        if (productState.length === 0) {
            dispatch(getAllProducts())
        }else {
            const cartObject = JSON.parse(localStorage.getItem('cart'));
            const cart = cartObject === null ? [] : cartObject.data;
            let productList = [];
            for (let i = 0; i < cart.length; i++) {
                const productName = cart[i];
                const product = productState.filter((i) => i.name === productName)[0];
                productList.push(product)
            }
            setCartProducts(productList);
        }
    }, [productState])

    useEffect(() => {
        if (quotationFormState !== null) {
            alert.success('Form is successfully submitted.')
            setFormData({
                name: '',
                email: '',
                message: ''
            })
            localStorage.setItem('cart', JSON.stringify(null))
            setCartProducts([]);
        }

    }, [quotationFormState])

    const HandleRequestOffer = () => {
        const cartObject = JSON.parse(localStorage.getItem('cart'));
        const cart = cartObject === null ? [] : cartObject.data;

        dispatch(submitQuotationForm(
            formData.name,
            formData.email,
            formData.message,
            JSON.stringify(cart)
        ))
    }




    return (
        <Fragment>
            <Header options={options} />

            <PageTitle name="Cart"/>

            {/* start cart-section */}
            <section className="cart-section woocommerce-cart section-padding">
                <div className="container-1410">
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="woocommerce">
                                <form action="/" method="post">
                                    <table className="shop_table shop_table_responsive cart">
                                        <thead>
                                        <tr>
                                            <th className="product-remove">&nbsp;</th>
                                            <th className="product-thumbnail">&nbsp;</th>
                                            <th className="product-name">Product</th>
                                            <th className="product-price">Unit Price</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            cartProducts && cartProducts.map((item, index) => (
                                                <CartItem key={index} data={item}/>
                                            ))
                                        }
                                        {/*<Coupon/>*/}
                                        </tbody>
                                    </table>
                                </form>
                                {/*<div className="cart-collaterals">
                                    <CalculatedShipping currencySymbol="£" price="430.00"/>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end cart-section */}

            {/*start form section*/}
            <section className="contact-section contact-pg-section section-padding">
                <div className="container-1410">
                    <div className="row">
                        <div className="col col-xs-12" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <div style={{width: '60%'}}>
                                <h2>Get an offer from us!</h2>
                                <div className="contact-form">
                                    <div>
                                        <input
                                            onChange={(e) => handleInputChange(setFormData, e)} type="text" name="name" id="name" placeholder="Name*"
                                            value={formData.name}
                                        />
                                    </div>
                                    <br /><br />
                                    <div>
                                        <input
                                            onChange={(e) => handleInputChange(setFormData, e)} type="email" name="email" id="email" placeholder="Email*"
                                            value={formData.email}
                                        />
                                    </div>
                                    <br /><br />
                                    <div className="fullwidth">
                                        <textarea
                                            onChange={(e) => handleInputChange(setFormData, e)} name="message" id="note" placeholder="Case Description..." defaultValue={""}
                                            value={formData.message}
                                        />
                                    </div>
                                    <br /><br />
                                    <div className="submit-area">
                                        <button onClick={HandleRequestOffer} className="theme-btn">Submit It Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/*end form section*/}

            {/*<Instagram/>*/}
            <Footer/>
        </Fragment>
    );
}

export default Cart;
