import React, {Fragment} from 'react';
import {handleDriveLink} from "../../utils";
import {useDispatch} from "react-redux";
import {addProductToCart, removeProductFromCart} from "../../redux/services";

/**
 * cart item component
 * @param data
 * @returns {*}
 * @constructor
 */
function CartItem({data}) {

    const dispatch = useDispatch();

    /*Cart actions */

    const removeFromCart = () => {
        dispatch(removeProductFromCart(data.name));
    }

    return (
        <Fragment>
            <tr className="cart_item">
                <td className="product-remove">
                    <a style={{cursor: 'pointer'}} onClick={removeFromCart} className="remove" title="Remove this item" data-product_id={data.id} data-product_sku="my name is">×</a>
                </td>
                <td className="product-thumbnail">
                    <a href="#">
                        <img width={57} height={70} src={handleDriveLink(data.googleDriveLink)} className="attachment-shop_thumbnail size-shop_thumbnail wp-post-image" alt="#"/>
                    </a>
                </td>
                <td className="product-name" data-title="Product">
                    <a href="#">{data.name}</a></td>
                <td className="product-price" data-title="Unit Price">
                    <span className="woocommerce-Price-amount amount">
                        <span className="woocommerce-Price-currencySymbol">{data.currencySymbol}</span>
                        ${data.price}
                    </span>
                </td>
            </tr>
        </Fragment>
    );
}

export default CartItem;
