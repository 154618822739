import React, {Fragment} from 'react';

/**
 * Product Categories Widget
 * @returns {*}
 * @constructor
 */
function ProductCategoriesWidget({categories, setCategories}) {

    return (
        <Fragment>
            <div className="widget woocommerce widget_product_categories">
                <h3>Filter by categories</h3>
                <ul className="product-categories">
                    {/*<li className="cat-item cat-parent">
                        <a href="#">Clothing</a>
                        <ul className="children">
                            <li className="cat-item cat-item-213">
                                <a href="#">Accessories</a>
                            </li>
                            <li className="cat-item cat-item-212">
                                <a href="#">Hoodies</a>
                            </li>
                            <li className="cat-item cat-item-211">
                                <a href="#">Tshirts</a>
                            </li>
                        </ul>
                    </li>*/}
                    <li className="cat-item" style={{cursor: 'pointer'}}>
                        <a
                            onClick={() => setCategories({...categories, womanSwimwear: !categories.womanSwimwear})}
                            style={{color: categories.womanSwimwear ? 'black' : 'gray'}}
                        >Woman Swimwear</a>
                    </li>
                    <li className="cat-item" style={{cursor: 'pointer'}}>
                        <a
                            onClick={() => setCategories({...categories, pijama: !categories.pijama})}
                            style={{color: categories.pijama ? 'black' : 'gray'}}
                        >Pijama</a>
                    </li>
                    <li className="cat-item" style={{cursor: 'pointer'}}>
                        <a
                            onClick={() => setCategories({...categories, islamicSwimwear: !categories.islamicSwimwear})}
                            style={{color: categories.islamicSwimwear ? 'black' : 'gray'}}
                        >Islamic Swimwear</a>
                    </li>
                    {/*<li className="cat-item" style={{cursor: 'pointer'}}>
                        <a
                            onClick={() => setCategories({...categories, kids: !categories.kids})}
                            style={{color: categories.kids ? 'black' : 'gray'}}
                        >Kids</a>
                    </li>*/}
                </ul>
            </div>
        </Fragment>
    );
}

export default ProductCategoriesWidget;
