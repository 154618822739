import {
    GET_PRODUCTS,
    ADD_PRODUCT_TO_CART,
    REMOVE_PRODUCT_FROM_CART,
    ADD_CONTACT_FORM,
    ADD_QUOTATION_FORM, ADD_EMAIL_SUB
} from "../actions";

export const getAllProducts = () => async dispatch => {
    try {
        const response = await fetch('https://anatoliantextilesapi.onrender.com/');
        const responseDto = await response.json();
        console.log(responseDto)
        dispatch({
            type: GET_PRODUCTS,
            payload: responseDto.data
        });
    }catch (e) {
        console.log(e)
    }
}

export const addProductToCart = (productName) => async dispatch => {
    const cartObject = JSON.parse(localStorage.getItem('cart'));
    const cart = cartObject === null ? [] : cartObject.data;
    cart.push(productName);
    const newCartObject = {data: cart};
    const stringified = JSON.stringify(newCartObject);
    localStorage.setItem('cart', stringified)
    dispatch({
        type: ADD_PRODUCT_TO_CART,
        payload: productName
    });
}

export const removeProductFromCart = (productName) => async dispatch => {
    const cartObject = JSON.parse(localStorage.getItem('cart'));
    const cart = cartObject === null ? [] : cartObject.data;
    const removedCart = cart.filter((i) => i !== productName);
    const newCartObject = {data: removedCart};
    const stringified = JSON.stringify(newCartObject);
    localStorage.setItem('cart', stringified)
    dispatch({
        type: REMOVE_PRODUCT_FROM_CART,
        payload: productName
    });
}

export const submitContactForm = (name, email, message) => async dispatch => {
    const formData = {
        name, email, message
    }
    try {
        const response = await fetch('https://anatoliantextilesapi.onrender.com/addContactForm', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
        });
        const responseDto = await response.json();
        console.log(responseDto)
        dispatch({
            type: ADD_CONTACT_FORM,
            payload: responseDto.data
        });
    }catch (e) {
        console.log(e)
    }
}

export const submitQuotationForm = (name, email, message, productListString) => async dispatch => {
    const formData = {
        name, email, message, productListString
    }
    try {
        const response = await fetch('https://anatoliantextilesapi.onrender.com/addRequestQuotation', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
        });
        const responseDto = await response.json();
        console.log(responseDto)
        dispatch({
            type: ADD_QUOTATION_FORM,
            payload: responseDto.data
        });
    }catch (e) {
        console.log(e)
    }
}

export const addEmailSub = (email) => async dispatch => {
    const formData = {
        email
    }
    try {
        const response = await fetch('https://anatoliantextilesapi.onrender.com/addEmailSubscription', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
        });
        const responseDto = await response.json();
        console.log(responseDto)
        dispatch({
            type: ADD_EMAIL_SUB,
            payload: responseDto.data
        });
        localStorage.setItem('userEmail', JSON.stringify({data: email}))
    }catch (e) {
        console.log(e)
    }
}
