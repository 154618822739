import React, {Fragment} from 'react';

/**
 * Search Widget
 * @param title
 * @returns {*}
 * @constructor
 */
function SearchWidget({searchText, setSearchText}) {

    return (
        <Fragment>
            <div className="widget search-widget">
                {
                    searchText.length > 0 ? <h3>{searchText}</h3> : ''
                }

                <form>
                    <div>
                        <input onChange={(e) => setSearchText(e.target.value)} type="text" placeholder="Search Product.."/>
                        {/*<button type="submit"><i className="ti-search"/></button>*/}
                    </div>
                </form>
            </div>
        </Fragment>
    );
}

export default SearchWidget;
