// store.js

import {applyMiddleware, createStore} from 'redux';
import rootReducer from './reducers'; // You'll create this file next
import {thunk} from 'redux-thunk';

const store = createStore(
    rootReducer,
    applyMiddleware(thunk)
);

export default store;
