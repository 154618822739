import React, {Fragment} from 'react';
import {Link} from "react-router-dom";

/**
 * CTA action display on default home page
 * @returns {*}
 * @constructor
 */
function CtaSection() {

    return (
        <Fragment>
            {/* start cta-section */}
            <section className="cta-section">
                <div className="container-1410">
                    <div className="row">
                        <div className="col col-xs-12">
                            <div style={{marginTop: 120}} />
                            <div className="content-area">
                                <span>Trending</span>
                                <h3 style={{color: 'black'}}>Where Tradition Meets Trend</h3>
                                <p style={{color: 'black'}}>
                                    Explore the unique blend of traditional Anatolian craftsmanship and contemporary fashion with our exclusive range of swimwear and Islamic clothing. Each piece is designed with attention to detail, ensuring style without compromising on comfort. Dive into our diverse collection and find the perfect attire that speaks to your style. Shop now and discover the latest trends from the heart of Turkey.
                                </p>
                                    <Link className="theme-btn-s5" to="/shop">
                                        Shop now
                                    </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end cta-section */}
        </Fragment>
    );
}

export default CtaSection;
