import React, {useState, Fragment, useEffect} from 'react';
import Slider from "react-slick";

import Footer from '../../components/global/Footer';
import Instagram from '../../components/global/Instagram';
import PageTitle from '../../components/global/PageTitle';
import Header from '../../components/header/Header';
import ProductInfoTabs from '../../components/products/ProductInfoTabs';
import QuickView from '../../components/products/QuickView';
import RecentSingleProducts from '../../components/products/RecentSingleProducts';

import './shop.css';

/**
 * demo data
 */
import data from '../../data/singleProductDemo.json';
import {useDispatch, useSelector} from "react-redux";
import {addProductToCart, getAllProducts, removeProductFromCart} from "../../redux/services";
import {handleDriveLink} from "../../utils";
import {NavLink} from "react-router-dom";

/**
 * single shop page with  Slider Images
 * @param options
 * @returns {*}
 * @constructor
 */
function ShopSliderImages({options}) {

    const urlArray = window.location.href.split('/product')
    const productParam = urlArray[1].length === 0 || urlArray[1] === '?' ? null : urlArray[1].replace('?productName=','');

    const dispatch = useDispatch();
    const {
        productState,
        cartState
    } = useSelector((state) => ({
        productState: state.products.products,
        cartState: state.cart.products
    }))

    useEffect(() => {
        if (productState.length === 0) {
            dispatch(getAllProducts())
        }
    }, [productState])

    /**
     * states
     */
    const [showQuickView, setShowQuickView] = useState(false);
    const [quickViewData, setQuickViewData] = useState({});
    const [productCount, setProductCount] = useState(1);
    const [product, setProduct] = useState(null);

    useEffect(() => {
        if (productState.length !== 0) {
            const filt = productState.filter((i) => i.name === productParam);
            setProduct(filt[0])
        }
    }, [productState])

    /*Cart actions*/
    const addToCart = () => {
        dispatch(addProductToCart(productParam));
    }

    const removeFromCart = () => {
        dispatch(removeProductFromCart(productParam));
    }

    const renderAddOrRemoveButton = () => {
        if (cartState.length === 0) {
            return(
                <button onClick={addToCart} >Add to Cart</button>
            )
        }
        if (cartState.includes(productParam)) {
            return(
                <>
                    <button onClick={removeFromCart} >Remove from Cart</button>
                    <NavLink to="/cart" className="theme-btn-s3">Request an Offer</NavLink>
                </>

            )
        }
        return(
            <button onClick={addToCart} >Add to Cart</button>
        )
    }

    /**
     * Handle Product Count
     */
    const HandleProductCount = (e, data) => {
        e.preventDefault();
        if (data == 'plus') {
            setProductCount(productCount + 1);
        } else {
            if (productCount > 1) {
                setProductCount(productCount - 1);
            } else {
                setProductCount(1);
            }
        }
    };

    /**
     * Handel Quick View Data
     */
    const HandelQuickViewData = (e, item) => {
        e.preventDefault();
        setShowQuickView(!showQuickView);
        setQuickViewData(item);
    };

    /**
     * Handel Quick View Close
     */
    const HandelQuickViewClose = (e) => {
        e.preventDefault();
        setShowQuickView(false);
        setQuickViewData({});
    };

    /**
     * slider settings
     */
    const settings = {
        dots: false,
        infinite: true,
        speed: 3500,
        autoplaySpeed: 2000,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
    };

    return (
        <Fragment>
            {showQuickView
                ? <QuickView
                    data={quickViewData}
                    onQuickViewCloseClick={HandelQuickViewClose}
                />
                : ''
            }

            <Header options={options}/>

            {product === null ? <div>Loading</div>:
                <>
                    <PageTitle name={product.name}/>

                    {/* start shop-single-section */}
                    <section className="shop-single-section section-padding">
                        <div className="container-1410">
                            <div className="row">
                                <div className="col col-md-6">
                                    <div className="shop-single-slider slider-thumbnail">
                                        <Slider {...settings}>
                                            {
                                                data.images.map((item, index) => (
                                                    <div key={index}>
                                                        <img src={handleDriveLink(product.googleDriveLink)}/>
                                                    </div>
                                                ))
                                            }
                                        </Slider>
                                        <div className="slider-nav"></div>
                                    </div>
                                </div>
                                <div className="col col-md-6">
                                    <div className="product-details">
                                        <h2>{product.name}</h2>
                                        <div className="price">
                                            <span className="current">${product.price}</span>
                                            {/*<span className="old">{data.symbol}{data.oldPrice}</span>*/}
                                        </div>
                                        {/*<div className="rating">
                                    <i className="fi flaticon-star"/>
                                    <i className="fi flaticon-star"/>
                                    <i className="fi flaticon-star"/>
                                    <i className="fi flaticon-star"/>
                                    <i className="fi flaticon-star-social-favorite-middle-full"/>
                                    <span>{data.reviewCount}</span>
                                </div>*/}
                                        <p dangerouslySetInnerHTML={{__html: product.description.replaceAll(/\n/g, "<br /><br />")}}></p>
                                        <div className="product-option">
                                            {/*<form className="form">
                                        <div className="product-row">
                                            <div className="touchspin-wrap">
                                                <button
                                                    onClick={(e) => {
                                                        HandleProductCount(e, 'plus')
                                                    }} id="slider-thumbnail-touchspin-up" className="btn btn-default "
                                                    type="button"><i className="glyphicon glyphicon-chevron-up"></i>
                                                </button>
                                                <button
                                                    onClick={(e) => {
                                                        HandleProductCount(e, 'minus')
                                                    }}
                                                    id="slider-thumbnail-touchspin-down" className="btn btn-default "
                                                    type="button"><i className="glyphicon glyphicon-chevron-down"></i>
                                                </button>
                                                <input readOnly className="product-count" type="text"
                                                       value={productCount} name="product-count"/>
                                            </div>
                                            <div>
                                                <button type="submit">Add to cart</button>
                                            </div>
                                        </div>
                                    </form>*/}
                                            {renderAddOrRemoveButton()}
                                        </div>
                                        <div className="thb-product-meta-before">
                                            {/*<div className="add-to-wishlist">
                                        <a href="#" className="add_to_wishlist">
                                            <i className="pe-7s-like"/>
                                            <span>Add To Wishlist</span>
                                        </a>
                                    </div>*/}

                                            <div className="product_meta">
                                        <span className="sku_wrapper">Sizes: <span
                                            className="sku">{product.sizes}</span></span>
                                                <span className="posted_in">Categories:
                                                    {
                                                        product.category
                                                    }
                                        </span>
                                                {/*<span className="tagged_as">Tags:
                                                    {
                                                        data.tags.map((item, index) =>
                                                            <a key={index}
                                                               href={item.link}>
                                                                {item.name} {data.tags.length - 1 === index ? '' : ','}
                                                            </a>
                                                        )
                                                    }
                                                </span>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end col */}
                            </div>
                            {/* end row */}
                            {/*<div className="row">
                        <div className="col col-md-8 col-md-offset-2">
                            <ProductInfoTabs/>
                        </div>
                    </div>*/}
                            {/* end row */}
                            {/*<div className="row">
                        <div className="col col-xs-12">
                            <RecentSingleProducts onQuickViewClick={HandelQuickViewData}/>
                        </div>
                    </div>*/}
                        </div>
                        <div style={{display: 'flex', marginTop: 50}}>
                            <NavLink to="/shop" className="theme-btn-s3" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}> <i className="fi flaticon-back"/>Go Back to Shop</NavLink>
                        </div>
                        {/* end of container */}
                    </section>
                    {/* end of shop-single-section */}
                </>
            }



            {/*<Instagram/>*/}
            <Footer/>
        </Fragment>
    );
}

export default ShopSliderImages;
