export const handleDriveLink = (item) => {
    if (item === undefined || item === null) return "";
    const match = item.match(/\/(?:file\/d\/|open\?id=|drive\/folders\/)([^/]+)/);
    const id = match ? match[1] : "";
    const link = `https://drive.google.com/thumbnail?id=${id}&sz=w1000`
    return link;
}

export const handleInputChange = (setFormData, e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value
    }));
}
