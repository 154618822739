import React, {useState, Fragment, useEffect, useRef, CSSProperties} from 'react';

import Footer from '../../components/global/Footer';
import Instagram from '../../components/global/Instagram';
import Header from '../../components/header/Header';
import PageTitle from '../../components/global/PageTitle';
import Ordering from '../../components/shop/Ordering';
import QuickView from '../../components/products/QuickView';
import Pagination from '../../components/global/Pagination';
import SearchWidget from '../../components/widget/SearchWidget';
import PriceFilterWidget from '../../components/widget/PriceFilterWidget';
import ProductCategoriesWidget from '../../components/widget/ProductCategoriesWidget';
import ColorFilterWidget from '../../components/widget/ColorFilterWidget';
import TagFilterWidget from '../../components/widget/TagFilterWidget';

import {FadeLoader} from "react-spinners";

import './shop.css';

import OrderingToolbar from "../../components/shop/OrderingToolbar";
import Products from "../../components/shop/Products";

/**
 * demo data
 */
import productsData from '../../data/products.json';
import productsData_2 from '../../data/products.json';
import {useDispatch, useSelector} from "react-redux";
import {getAllProducts} from "../../redux/services";
const products = [...productsData, ...productsData_2];

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};


/**
 * Shop Page with Left Sidebar
 * @param options
 * @returns {*}
 * @constructor
 */
function LeftSidebar({ options }) {

    const elementRef = useRef(null);

    const dispatch = useDispatch();
    const {
        productState
    } = useSelector((state) => ({
        productState: state.products.products
    }))

    useEffect(() => {
        console.log(productState, 'products here')
        if (productState.length === 0) {
            dispatch(getAllProducts())
        }
        setProducts(productState)
    }, [productState])


    const urlArray = window.location.href.split('/shop')
    const categoryParam = urlArray[1].length === 0 || urlArray[1] === '?' ? null : urlArray[1].replace('?category=','');

    /**
     * states
     */
    const [showQuickView, setShowQuickView] = useState(false);
    const [quickViewData, setQuickViewData] = useState({});
    const [ordering, setOrdering] = useState(1);

    const [pagination, setPagination] = useState(0);
    const [paginationCounter, setPaginationCounter] = useState(1);
    const pageSize = 24;

    const [products, setProducts] = useState(productState);
    const [searchText, setSearchText] = useState('');
    const [priceRange, setPriceRange] = useState({
        min: 0,
        max: 50,
    });
    const [categories, setCategories] = useState({
        womanSwimwear: categoryParam === null ? false : categoryParam === 'ws' ? true : false,
        pijama: categoryParam === null ? false : categoryParam === 'p' ? true : false,
        islamicSwimwear: categoryParam === null ? false : categoryParam === 'is' ? true : false,
        kids: categoryParam === null ? false : categoryParam === 'kids' ? true : false,
    });

    const [filterOptions, setFilterOptions] = useState({
        searchText: false,
        priceRange: false,
        category: false
    })

    /*Spinner stuff*/
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#c2572b");

    useEffect(() => {
        if (searchText.length === 0) {
            setFilterOptions({
                searchText: false,
                priceRange: filterOptions.priceRange,
                category: filterOptions.category
            })
        }else {
            setFilterOptions({
                searchText: true,
                priceRange: filterOptions.priceRange,
                category: filterOptions.category
            })
        }
        HandleProductsByFilters();
    }, [searchText])

    useEffect(() => {
        HandleProductsByFilters()
    }, [filterOptions])
    useEffect(() => {
        if (categories.womanSwimwear || categories.pijama || categories.islamicSwimwear || categories.kids) {
            setFilterOptions({...filterOptions, category: true})
        }else {
            setFilterOptions({...filterOptions, category: false})
        }
    }, [categories])

    useEffect(() => {
        setPagination(0);
        setPaginationCounter(1);
    }, [products])

    /*Handle Filters*/

    const HandleProductsByFilters = () => {
        let filteredArray = productState;
        if (filterOptions.searchText) {
            filteredArray = filteredArray.filter((i) => i.name.includes(searchText));
        }
        if (filterOptions.category) {
            /*filteredArray = filteredArray.filter((i) => i.category === categories);*/
            /*Kids, Pijamas, Islamic Swimwear, Women Swimwear*/
            if (categories.kids) {
                filteredArray = filteredArray.filter((i) => i.category === 'Kids');
            }
            if (categories.pijama) {
                filteredArray = filteredArray.filter((i) => i.category === 'Pijamas');
            }
            if (categories.womanSwimwear) {
                filteredArray = filteredArray.filter((i) => i.category === 'Women Swimwear');
            }
            if (categories.islamicSwimwear) {
                filteredArray = filteredArray.filter((i) => i.category === 'Islamic Swimwear');
            }
        }
        if (filterOptions.priceRange) {
            filteredArray = filteredArray.filter((i) => i.price > priceRange.min && i.price < priceRange.max);
        }
        setProducts(filteredArray);
    }
    const HandlePriceFilter = () => {
        setFilterOptions({
            searchText: filterOptions.searchText,
            priceRange: true,
            category: filterOptions.category
        })
    }


    /*Handle pagination*/
    const HandlePaginationState = (increment) => {
        if (increment) {
            setPagination(pagination + pageSize);
            setPaginationCounter(paginationCounter + 1)
        }else {
            setPagination(pagination - pageSize);
            setPaginationCounter(paginationCounter - 1)
        }
        elementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    const HandlePaginationData = () => {
        if (products.length === 0) return [];
        return products.slice(pagination, pagination + pageSize);
    }
    const HandlePaginationStateByPageNumber = (pageNum) => {
        setPaginationCounter(pageNum);
        setPagination((pageNum - 1) * pageSize)
        elementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    const HandlePaginationActionButtons = () => {
        /*Is there a previous or next button*/
        if (pagination === 0) {
            return(
                {previous: false, next: true}
            )
        }
        if (pagination + pageSize > productState.length) {
            return(
                {previous: true, next: false}
            )
        }

        return(
            {previous: true, next: true}
        )
    }
    /**
     * Handle Ordering Status
     */
    const HandleOrderingStatus = (event, data) => {
        event.preventDefault();
        setOrdering(data);
    };

    /**
     * Handel Quick View Data
     */
    const HandelQuickViewData = (e, item) => {
        e.preventDefault();
        setShowQuickView(!showQuickView);
        setQuickViewData(item);
    };

    /**
     * Handel Quick View Close
     */
    const HandelQuickViewClose = (e) => {
        e.preventDefault();
        setShowQuickView(false);
        setQuickViewData({});
    };

    return (
        <Fragment>

            {showQuickView
                ? <QuickView
                    data={quickViewData}
                    onQuickViewCloseClick={HandelQuickViewClose}
                />
                : ''
            }

            <Header options={options} />

            <PageTitle name="Shop"/>


            {/* start shop-section */}
            <section className="shop-section section-padding">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="shop-area clearfix">
                                <div className="shop-sidebar">
                                    <SearchWidget searchText={searchText} setSearchText={setSearchText}/>
                                    <PriceFilterWidget
                                        rangeVal={priceRange}
                                        setRangeVal={setPriceRange}
                                        HandlePriceFilter={HandlePriceFilter}
                                    />
                                    <ProductCategoriesWidget
                                        categories={categories}
                                        setCategories={setCategories}
                                    />
                                    {/*<ColorFilterWidget/>
                                    <TagFilterWidget/>*/}
                                </div>
                                <br /><br />
                                <div className="woocommerce-content-wrap">
                                    <div className="woocommerce-content-inner">
                                        <div className="woocommerce-toolbar-top">
                                            <p className="woocommerce-result-count">Showing {(pageSize*(paginationCounter - 1)) + 1}–{pageSize*paginationCounter} of {products.length} results</p>

                                            <OrderingToolbar
                                                HandleOrderingStatus={HandleOrderingStatus}
                                                ordering={ordering}
                                                elementRef={elementRef}
                                            />

                                            {/*<Ordering/>*/}
                                        </div>


                                        {/*Spinner here when products' length is 0*/}
                                        {products.length === 0 ?
                                            <div>
                                                <FadeLoader
                                                    color={color}
                                                    loading={loading}
                                                    cssOverride={override}
                                                    size={150}
                                                    aria-label="Loading Spinner"
                                                    data-testid="loader"
                                                />
                                            </div>:
                                            <Products
                                            HandelQuickViewData={HandelQuickViewData}
                                            getProductSlice={HandlePaginationData}
                                            ordering={ordering}
                                        />}


                                    </div>
                                    <Pagination
                                        extraClass=""
                                        HandlePaginationActionButtons={HandlePaginationActionButtons}
                                        paginationCounter={paginationCounter}
                                        pageNumber={Math.ceil(products.length / 24)}
                                        HandlePaginationState={HandlePaginationState}
                                        HandlePaginationStateByPageNumber={HandlePaginationStateByPageNumber}
                                    />
                                </div>
                                {/*<div className="shop-sidebar">
                                    <SearchWidget searchText={searchText} setSearchText={setSearchText}/>
                                    <PriceFilterWidget
                                        rangeVal={priceRange}
                                        setRangeVal={setPriceRange}
                                        HandlePriceFilter={HandlePriceFilter}
                                    />
                                    <ProductCategoriesWidget
                                        categories={categories}
                                        setCategories={setCategories}
                                    />
                                    <ColorFilterWidget/>
                                    <TagFilterWidget/>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end container */}
            </section>
            {/* end shop-section */}

            {/*<Instagram/>*/}
            <Footer/>

        </Fragment>
    );
}

export default LeftSidebar;
