// reducers/index.js

import { combineReducers } from 'redux';
import {productsReducer, cartReducer, formsReducer} from "./actions";
// Import your reducers here
// import exampleReducer from './exampleReducer';

const rootReducer = combineReducers({
    products: productsReducer,
    cart: cartReducer,
    forms: formsReducer
});

export default rootReducer;
