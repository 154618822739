import React, {Fragment, useState, useEffect} from 'react';

import Footer from '../../components/global/Footer';
import Instagram from '../../components/global/Instagram';
import Header from '../../components/header/Header';
import PageTitle from '../../components/global/PageTitle';
import {handleInputChange} from "../../utils";
import {useDispatch, useSelector} from "react-redux";
import {submitContactForm} from "../../redux/services";
import { useAlert } from 'react-alert'



/**
 * ContactUs page
 * @param options
 * @returns {*}
 * @constructor
 */
function ContactUs({ options }) {

    const alert = useAlert()
    const dispatch = useDispatch();
    const {
        contactFormState
    } = useSelector((state) => ({
        contactFormState: state.forms.contactForm
    }))

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    })

    const onSubmitForm = (e)=> {
        e.preventDefault();
        dispatch(submitContactForm(
            formData.name,
            formData.email,
            formData.message
        ))
    };

    useEffect(() => {
        if (contactFormState !== null) {
            alert.success('Form is successfully submitted.')
            setFormData({
                name: '',
                email: '',
                message: ''
            })
        }

    }, [contactFormState])

    /**
     * demo data
     */
    const contactUsData = {
        address: "Halkapınar, Mürselpaşa Blv. Yanyolu No:166, 35170 Konak/İzmir",
        phone_1: "+90 539 564 77 57",
        email_1: "ozgurkoca@anatoliantex.com",
        email_2: "yasartuzun@anatoliantex.com",
        email_3: "support@anatoliantex.com",
        time: "10AM - 5 PM, Sunday closed"
    };

    return (
        <Fragment>
            <Header options={options} />

            <PageTitle name="Contact"/>

            {/* start contact-section */}
            <section className="contact-section contact-pg-section section-padding">
                <div className="container-1410">
                    <div className="row">
                        <div className="col col-lg-10 col-lg-offset-1">
                            <div className="contact-info">
                                <ul>
                                    <li>
                                        <i className="pe-7s-culture"/>
                                        <h4>Office address</h4>
                                        <p>{contactUsData.address}</p>
                                    </li>
                                    <li>
                                        <i className="pe-7s-phone"/>
                                        <h4>Phone number</h4>
                                        <p>{contactUsData.phone_1}</p>
                                    </li>
                                    <li>
                                        <i className="pe-7s-mail"/>
                                        <h4>Email us</h4>
                                        <p>{contactUsData.email_1} <br/>{contactUsData.email_2}  <br/>{contactUsData.email_3}</p>
                                    </li>
                                    <li>
                                        <i className="pe-7s-alarm"/>
                                        <h4>Office time</h4>
                                        <p>{contactUsData.time}</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="contact-form-col">
                                <h2>Let’s talk to us</h2>
                                <div className="contact-form">
                                    <form method="post" className="contact-validation-active" id="contact-form-main">
                                        <div>
                                            <input
                                                onChange={(e) => handleInputChange(setFormData, e)}
                                                type="text" name="name" id="name" placeholder="Name*"
                                                value={formData.name}
                                            />
                                        </div>
                                        <div>
                                            <input
                                                onChange={(e) => handleInputChange(setFormData, e)} type="email" name="email" id="email" placeholder="Email*"
                                                value={formData.email}
                                            />
                                        </div>

                                        <div className="fullwidth">
                                            <textarea
                                                onChange={(e) => handleInputChange(setFormData, e)} name="message" id="note" placeholder="Case Description..." defaultValue={""}
                                                value={formData.message}
                                            />
                                        </div>
                                        <div className="submit-area">
                                            <button onClick={onSubmitForm} type="submit" className="theme-btn">Submit It Now</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end contact-section-s3 */}

            {/*  start contact-map */}
            <section className="contact-map-section">
                <h2 className="hidden">Contact map</h2>
                <div className="contact-map">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3125.351133723534!2d27.158453476489736!3d38.433357673950276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bbd9d94cb9567f%3A0x1bba077fbd1520b8!2sFolkart%20Vega!5e0!3m2!1str!2str!4v1714655202611!5m2!1str!2str"
                        allowFullScreen/>

                </div>
            </section>
            {/* end contact-map */}

            {/*<Instagram/>*/}
            <Footer/>
        </Fragment>
    );
}

export default ContactUs;
