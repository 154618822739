import React, {Fragment} from 'react';

/**
 * Pagination component
 * @param extraClass
 * @returns {*}
 * @constructor
 */
function Pagination({
    extraClass,
    HandlePaginationActionButtons,
    paginationCounter,
    pageNumber,
    HandlePaginationState,
    HandlePaginationStateByPageNumber
}) {
    const paginationButtonBools = HandlePaginationActionButtons();


    const renderPageNumbers = () => {
        const elements = [];
        for (let i = 1; i < pageNumber + 1; i++) {
            const element = (
                <li
                    className={paginationCounter === i ? "active" : ''}
                    style={{cursor: 'pointer'}}
                >
                    <a onClick={() => HandlePaginationStateByPageNumber(i)}>{i}</a>
                </li>
            )
            elements.push(element);
        }
        return elements
    }

    return (
        <Fragment>
            <div className={"pagination-wrapper " + extraClass}>
                <ul className="pg-pagination">
                    <li style={{cursor: 'pointer'}}>
                        {paginationButtonBools.previous ?
                            <a onClick={() => HandlePaginationState(false)} aria-label="Previous">
                                <i className="fi flaticon-back"/>
                            </a> : null}

                    </li>

                    {renderPageNumbers()}
                    <li style={{cursor: 'pointer'}}>
                        {paginationButtonBools.next ?
                            <a onClick={() => HandlePaginationState(true)} aria-label="Next">
                                <i className="fi flaticon-next"/>
                            </a> : null
                        }

                    </li>
                </ul>
            </div>
        </Fragment>
    );
}

export default Pagination;
