// actions/index.js

// Action types
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART';
export const ADD_CONTACT_FORM = 'ADD_CONTACT_FORM';
export const ADD_QUOTATION_FORM = 'ADD_QUOTATION_FORM';
export const ADD_EMAIL_SUB = 'ADD_EMAIL_SUB';


const productInitialState = {
    products: [],
};

const cartInitialState = {
    products: [],
};

const formInitialState = {
    contactForm: null,
    quotationForm: null,
    emailSubForm: null
}

export const productsReducer = (state = productInitialState, action) => {
    switch (action.type) {
        case GET_PRODUCTS:
            return {...state, products: action.payload};
        default:
            return state;
    }
};

export const cartReducer = (state = cartInitialState, action) => {
    switch (action.type) {
        case ADD_PRODUCT_TO_CART:
            return {...state, products: [...state.products, action.payload]};
        case REMOVE_PRODUCT_FROM_CART:
            return {...state, products: state.products.filter(product => product !== action.payload)};
        default:
            return state;
    }
};

export const formsReducer = (state = formInitialState, action) => {
    switch (action.type) {
        case ADD_CONTACT_FORM:
            return {...state, contactForm: action.payload};
        case ADD_QUOTATION_FORM:
            return {...state, quotationForm: action.payload};
        case ADD_EMAIL_SUB:
            return {...state, emailSubForm: action.payload};
        default:
            return state;
    }
};
