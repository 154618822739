import React, {Fragment} from 'react';
import {useState, useEffect} from 'react';
import { useAlert } from 'react-alert'
import {useDispatch, useSelector} from "react-redux";
import {addEmailSub} from "../../redux/services";

/**
 * Newsletter Popup component
 * @returns {*}
 * @constructor
 */
function NewsletterPopup() {

    const {
        emailSubForm
    } = useSelector((state) => ({
        emailSubForm: state.forms.emailSubForm
    }))

    const alert = useAlert()
    const dispatch = useDispatch();

    const [showNewsletter, setShowNewsletter] = useState(false);
    const [email, setEmail] = useState('');
    const [userEmail, setUserEmail] = useState(JSON.parse(localStorage.getItem('userEmail')));

    const HandelNewsletterClose = () => {
        setShowNewsletter(false);
    };

    useEffect(() => {
        setTimeout(() => {
            setShowNewsletter(true);
        }, 1000)
    }, []);

    const onSendEmail = (e)=> {
        e.preventDefault();
        dispatch(addEmailSub(email));
    };

    useEffect(() => {
        if (emailSubForm !== null) {
            alert.success('Your email is added successfully.')
            setEmail('');
            setUserEmail(JSON.parse(localStorage.getItem('userEmail')));
        }
    }, [emailSubForm])

    const renderComponent = () => {

        if (userEmail !== null) return null;

        return(
            <section className={"newsletter-popup-area-section " + (showNewsletter ? 'active-newsletter-popup' : '')}>
                <div className="newsletter-popup-area">
                    <div className="newsletter-popup-ineer">
                        <button onClick={HandelNewsletterClose} className="btn newsletter-close-btn"><i
                            className="pe-7s-close-circle"/></button>
                        <div className="img-holder">
                            <img src={process.env.PUBLIC_URL + "/assets/images/others/emailBanner.png"} loading="lazy" alt=""/>
                        </div>
                        <div className="details">
                            <h4>Wholesale Only</h4>
                            <p>
                                We exclusively offer products for wholesale. To discuss bulk order details, add the desired products to your cart and click 'Request Offer'. Our sales representative will assist you with pricing, delivery, and customs information.
                                <br /><br />Subscribe to our newsletter for updates on our exclusive wholesale deals.
                            </p>
                            <form>
                                <div>
                                    <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Enter your email"/>
                                    <button onClick={onSendEmail}>Subscribe</button>
                                </div>
                                <div>
                                    <label className="checkbox-holder"> Don't show this popup again!
                                        <input onChange={() => setUserEmail({data: 'dont want to see anything '})} type="checkbox" className="show-message"/>
                                        <span className="checkmark"/>
                                    </label>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    return (
        <Fragment>
            {/* start newsletter-popup-area-section */}
            {renderComponent()}
            {/* end newsletter-popup-area-section */}
        </Fragment>
    );
}

export default NewsletterPopup;
