import React, {Fragment, useEffect, useState} from 'react';
import {useAlert} from "react-alert";
import {useDispatch, useSelector} from "react-redux";
import {addEmailSub} from "../../redux/services";

/**
 * Newsletter Widget
 * @returns {*}
 * @constructor
 */
function NewsletterWidget() {


    const {
        emailSubForm
    } = useSelector((state) => ({
        emailSubForm: state.forms.emailSubForm
    }))

    const [email, setEmail] = useState('');
    const alert = useAlert()
    const dispatch = useDispatch();

    const onSendEmail = (e)=> {
        e.preventDefault();
        dispatch(addEmailSub(email));
    };

    useEffect(() => {
        if (emailSubForm !== null) {
            alert.success('Your email is added successfully.')
            setEmail('');
        }
    }, [emailSubForm])

    return (
        <Fragment>
            <div className="widget newsletter-widget">
                <div className="inner">
                    <h3>Wholesale Only</h3>
                    <p>Subscribe to our newsletter for updates on our exclusive wholesale deals.</p>
                    <form>
                        <div className="input-1">
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Email Address *"
                                   required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="submit clearfix">
                            <button onClick={onSendEmail}>Subscribe</button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    );
}

export default NewsletterWidget;
